$(function() {
    $('[data-fancybox]').fancybox({
        buttons : [
            'close'
        ],
        loop: true
    });

    $('[data-fancybox]').fancybox({
        buttons : [
            'close'
        ],
        loop: true
    });


});