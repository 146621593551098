$(function() {
    $('.slider').slick({
        autoplay: true,
        arrows: false,
        dots: true,
        infinite: true,
        speed: 2000
    });

    $('.slider-shows').slick({
        autoplay: false,
        arrows: false,
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        speed: 1000
    });
});

$(".slide").on("click", ".slick-dots", function(event) {
    event.preventDefault();

});